import DeviceSupport from "@components/atoms/DeviceSupport";
import { HumanloopMark } from "@components/library/HumanloopMark";
import { AuthSelector } from "@components/llama/Signup/AuthSelector";
import LoginForm from "@components/llama/Signup/LoginForm";
import SignupLayout from "@components/llama/Signup/SignupLayout";
import CustomHead from "@components/templates/CustomHead";
import { useAuth } from "lib/use-auth";
import { safeQueryParamRedirect } from "lib/xss-prevention";
import Link from "next/link";
import { SIGNUP_URL } from "@/lib/constants";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useState } from "react";
import SSOForm from "@components/llama/Signup/SSOForm";

const LoginPage: NextTypes.NextPageWithLayout = () => {
  const auth = useAuth();
  const router = useRouter();
  const [showEmailLoginForm, setShowEmailLoginForm] = useState(false); // Whether to show the login form or the auth selector
  const [showSSOForm, setShowSSOForm] = useState(false);

  useEffect(() => {
    if (!auth.loading && auth.user) {
      router.replace(safeQueryParamRedirect(router.query.next));
    }
  });

  return (
    <div className="flex w-[320px] flex-col justify-between gap-40">
      <div className="flex flex-col items-center gap-24">
        <HumanloopMark className="h-32 w-32" />
        <h1 className="text-18-24 font-bold">Welcome back to Humanloop</h1>
      </div>
      <div className="flex grow flex-col justify-start">
        {showEmailLoginForm ? (
          <LoginForm onBackClick={() => setShowEmailLoginForm(false)} />
        ) : showSSOForm ? (
          <SSOForm onBackClick={() => setShowSSOForm(false)} />
        ) : (
          <AuthSelector
            onSSO={() => setShowSSOForm(true)}
            onEmail={() => setShowEmailLoginForm(true)}
            role={"Log in"}
          />
        )}
      </div>
      <div className="flex flex-col gap-24 text-center text-12-16 text-gray-700">
        <div>
          Need a Humanloop account?{" "}
          <Link href={SIGNUP_URL} className="text-oldblue-600">
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return (
    <SignupLayout>
      <DeviceSupport />
      <CustomHead
        title="Log in"
        description="Log in to your Humanloop account. Humanloop is the LLM evals platform for enterprises."
      />
      {page}
    </SignupLayout>
  );
};

export default LoginPage;
